import React from 'react'
import { graphql } from 'gatsby';
import Image from "gatsby-image";

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';
import Hero from 'components/Hero';
import "styles/MissieVissie.scss";

import listIcon from 'img/list-icon.svg';

const VisiePage = ({ data }) => {
  const { wordpressPage: post } = data

  return (
    <Layout>
      <SEO yoast={{ meta: post.yoast_meta }} />
      <div className="visie-missie-page">

        <Hero
          className="klantcase-overview-hero"
          fluidImage={post.acf.banner.background_image.localFile.childImageSharp.fluid}
          column={false}
          useH1={true}
          title={post.acf.banner.title}
        />
     

      <section>
        <div className="container">
          <div className="row pt-2 pb-5">
            <div className="col-lg-2">
              <h2 className="with-dot-nested mb-4">
                <Content content={post.acf.visie_text.title} />
              </h2>
            </div>
            <div className="col-lg-10">
              <Content content={post.acf.visie_text.description} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row py-5 align-items-center">
          <div className="col-lg-6">
            <Image fluid={post.acf.client.image.localFile.childImageSharp.fluid} />
          </div>
          <div className="col-lg-6 d-flex justify-content-center mt-lg-n5 mt-3">
            <img className="logo" src={post.acf.client.logo.localFile.publicURL} width="454" height="164" />
          </div>
        </div>
      </section>

      <section>
        <Image
          loading="eager"
          fadeIn={false}
          fluid={post.acf.missie.background_image.localFile.childImageSharp.fluid}
          className="missie-image position-absolute"
        />
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-6">
              <h2 className="with-dot-nested mb-4">
                <Content content={post.acf.missie.title} />
              </h2>
              <Content content={post.acf.missie.description} />
              <div className="quote my-5">
                <Content content={post.acf.missie.quote} />
              </div>
            </div>
            <div className="col-lg-6 pr-lg-5">
              <div className="pl-lg-5">
                <Content content={post.acf.missie.corevalues.title} className="mb-5" />
                <ul className="corevalues">
                  {post.acf.missie.corevalues.usps.map(({ item }, index) => (
                    <li key={index} className="d-flex align-items-center">
                      <img src={listIcon} />
                      <span className="ml-2">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    </Layout>
  )
}

export default VisiePage

export const query = graphql`
  query VisiePageByID($wordpress_id: Int!) {
    wordpressPage(
        wordpress_id: { eq: $wordpress_id }
    ) {   
      wordpress_id
      title
      slug
      
      acf {
        banner {
          title
          background_image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        visie_text {
          title
          description
        }

        client {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          logo {
            localFile {
              publicURL
            }
          }
        }

        missie {
          background_image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          description
          quote
          title
          corevalues {
            title
            usps {
              item
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`
